import { createSlice } from "@reduxjs/toolkit";

import {
  userProfileDetail,
  userProfileDetailUpdate,
} from "./userProfileAction";

const initialState = {
  usersDetail: {},
  updateUsersDetail: {},
  message: null,
  success: null,
  loading: false,
  err: null,
};

export const userProfileDetailSlice = createSlice({
  name: "userProfileDetail",
  initialState,
  reducers: {
    clearUserDetails: (state, action) => {
      state.usersDetail = {};
      state.success = false;
    },
    clearEmailErr: (state, action) => {
      state.err = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(userProfileDetail.fulfilled, (state, action) => {
        state.usersDetail = action?.payload?.data?.detail;
        state.message = action?.payload?.message;
        state.success = true;
        state.loading = false;
      })
      .addCase(userProfileDetail.pending, (state, action) => {
        state.usersDetail = {};
        state.message = null;
        state.loading = true;
        state.success = false;
      })
      .addCase(userProfileDetail.rejected, (state, action) => {
        state.usersDetail = {};
        state.message = action?.payload?.response?.data?.message;
        state.loading = false;
        state.success = false;
      })

      .addCase(userProfileDetailUpdate.fulfilled, (state, action) => {
        state.updateUsersDetail = action?.payload?.data?.detail;
        state.message = action?.payload?.message;
        state.err = null;
        state.success = true;
        state.loading = false;
      })
      .addCase(userProfileDetailUpdate.pending, (state, action) => {
        state.message = null;
        state.loading = true;
        state.success = false;
      })
      .addCase(userProfileDetailUpdate.rejected, (state, action) => {
        state.message = action?.payload?.response?.data?.message;
        state.err = action?.payload?.response?.data?.detail?.message;
        state.loading = false;
        state.success = false;
      });
  },
});

export const { clearUserDetails, clearEmailErr } =
  userProfileDetailSlice.actions;

export default userProfileDetailSlice.reducer;
