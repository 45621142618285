import { createAsyncThunk } from "@reduxjs/toolkit";

import Request from "../../helpers/request.service";

export const associatesListing = createAsyncThunk(
  "associatesListing",
  async (props, { rejectWithValue }) => {
    try {
      let response;
      response = await Request.get(`/users/data`, props?.params);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const associatesDelete = createAsyncThunk(
  "associatesDelete",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.delete(`/users/${props}`);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const riskStatusGraph = createAsyncThunk(
  "riskStatusGraph",
  async (props, { rejectWithValue }) => {
    try {
      const response = await Request.get(`/get-risk-status/${props.id}`, {
        duration: props.duration,
      });

      return response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
